import React from 'react';
import ReactPlayer from 'react-player'

class manuell506 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>第三方美/饿订单</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell506/manuell506.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell506/manuell506-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              第三方美/饿订单：支持第三方“美团”和“饿了么”订单发送到系统内部，并由系统内骑手进行配送在“商家端“APP-”个人中心“进行”美/饿授权“。
            </p>
            <div className='d-flex align-items-center' style={{padding: '10px 0 0'}}>
              <img style={{ display: 'block' ,width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell506/manuell506-2.png'/>
            </div>
        </div>
    )
  }
}

export default manuell506;