import React from 'react';
import ReactPlayer from 'react-player'

class manuell507 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>第三方接口订单</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell506/manuell506.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell506/manuell506-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              第三方接口订单：支持第三方平台订单通过接口形式发送到系统内部，并由系统内骑手进行配送。<br/>
              可在“趣来达开发平台”中申请开发者账号，并通过接口模式发往系统内部平台<br/>
              趣来达开发平台地址：https://www.qolai.com/developer#/user/login
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell507/manuell507-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell507/manuell507-3.png'/>
        </div>
    )
  }
}

export default manuell507;