import React from 'react';

class manuell42 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>二级页面配置</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              什么是二级页面？<br/>
              小程序支持对首页的跳转页面进行二级页面设置，在“外卖设置”--“外卖配置”右上角点击“二级页面”，即可自定义配置“二级页面”。二级页面支持对商家进行“营业类目分类筛选”。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell42/manuell42-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '400px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell42/manuell42-3.jpg'/>
        </div>
    )
  }
}

export default manuell42;