/**
 * @FileDescription: 联系我们
 * @Author: Versient
 * @Date: 2024-09-10 17:12:19
 * @LastEditors: Versient
 * @LastEditTime: 2024-09-18 15:50:48
 */
"use client"

import React, { useState } from 'react'
import axios from 'axios';
import Title from "../../../../components/Title_2";
import {userSubmit} from "../../../../apis/About"
// import { fetchData } from "@/app/request.js";
import { message } from "antd";
import '../page.css'

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    remark: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // 处理表单提交  
  const handleSubmit = async () => {
    // 这里可以进行表单验证或数据发送  
    const email_reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    const phone_myreg = /^1[3-9]\d{9}$/;
    if (!formData.name) {
      message.warning('请填写姓名');
      return
    }
    if (!formData.phone || (formData.phone && !phone_myreg.test(formData.phone))) {
      message.warning('请填写正确的电话号码');
      return
    }
    if (formData.email && !email_reg.test(formData.email)) {
      message.warning('请填写正确的邮箱地址');
      return
    }
    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      remark: formData.remark,
    }
    try {
      const result =await userSubmit(formData.name,formData.email,formData.phone,formData.remark);
      if (result.code == 200) {
        message.success('提交成功！')
        setFormData({ name: '', phone: '', email: '', remark: '' });
      } else {
        message.error(result.message)
      }
    } catch (error) { }
  };

  return (
    <div className="middle-container h-[660px]">
      <Title
        baseInfo={{
          title: "联系我们 ",
          englishAlias: "CONTACT",
          titleStyle: { marginTop: '0' },
          englishStyle: { fontSize: '36px', top: '38px' },
          indicatorStyle: { bottom: -16 }
        }}
      />
      <div className="card-container w-[100%] h-[480px] py-[60px] flex flex-col items-center justify-between">

        <div>
          <div className="flex justify-between">
            <div className="flex-none user-info-form">
              <div className="w-[390px] h-[50px] py-[15px] px-[20px] flex items-center bg-[#F8F8F8] rounded-[10px]">
                <label className="text-[#121213] font-bold mr-[14px] text-[20px] leading-[32px]">姓名</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="请输入您的姓名"
                  className="input-default flex-1  border-none rounded focus:outline-none focus:ring-0"
                />
              </div>
            </div>

            <div className="flex-none user-info-form mx-[20px]">
              <div className="w-[390px] h-[50px] py-[15px] px-[20px] flex items-center bg-[#F8F8F8] rounded-[10px]">
                <label className="text-[#121213] font-bold mr-[14px] text-[20px] leading-[32px]">电话</label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="请输入您的电话"
                  className="input-default flex-1  border-none rounded focus:outline-none focus:ring-0"
                />
              </div>
            </div>

            <div className="flex-none user-info-form">
              <div className="w-[390px] h-[50px] py-[15px] px-[20px] flex items-center bg-[#F8F8F8] rounded-[10px]">
                <label className="text-[#121213] font-bold mr-[14px] text-[20px] leading-[32px]">邮箱</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="请输入您的邮箱"
                  className="input-default flex-1  border-none rounded focus:outline-none focus:ring-0"
                />
              </div>
            </div>
          </div>

          <div className="user-info-form box-border py-[20px] px-[28px]"
            style={{
              width: '100%',
              height: '180px',
              marginTop: '20px',
              backgroundColor: '#F8F8F8',
              borderRadius: '10px',
            }}
          >
            <textarea
              rows="4"
              name="remark"
              value={formData.remark}
              onChange={handleChange}
              placeholder="请输入您的需求或建议"
              className="textarea-default w-[100%] flex-1  border-none rounded focus:outline-none focus:ring-0"
            />
          </div>
        </div>
        <div className="user-info-submit hover:scale-110 duration-[.5s]" onClick={handleSubmit}>申请使用 more+</div>
      </div>

    </div>
  )
}

export default ContactUs
