import React from 'react';
import ReactPlayer from 'react-player'

class manuell02 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>智能调度</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell02/manuell02.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell02/manuell02-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>系统内存在两种调度模式统一调度和智能调度</p>
            <img style={{ display: 'block' ,marginBottom: '40px', width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell02/manuell02-2.png'/>
            <p style={{margin: '15px 0'}}>统一调度：订单将统一发送到设置的抢单池里，抢单池内所有骑手都可抢单。</p>
            <p style={{margin: '15px 0 10px'}}>智能调度：订单将根据下方设置的条件按照顺序派发给满足条件的骑手。</p>
            <p style={{margin: '0px 0 0px 70px'}}>
              存在“运力不足”和“运力充足”不同情况下的派发触发规则。<br/>
              派发规则：<br/>
              ①持单量规则<br/>
              ②总接单量规则<br/>
              ③距离取单地址范围规则<br/>
              ④顺路规则<br/>
              ⑤等待抢单时间规则<br/>
              ⑥跑单量最高规则/持单量最少规则<br/>
              智能调度支持“强制指派“、”自动改派“、”调度失败“、”回程单“等功能设置。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px', width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell02/manuell02-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px', width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell02/manuell02-4.jpg'/>
        </div>
    )
  }
}

export default manuell02;