import React from 'react';
import ReactPlayer from 'react-player'

class manuell24 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>商家管理</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell24/manuell24.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell24/manuell24-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              如何更好的对骑手进行高效管理？<br/>
              系统支持高度控制商家设置功能权限，可自定义控制商家是否有设置此功能的权限，以免商家随意更改相关内容。在“商户管理”--“商户列表”--“选择商家、点击编辑”--“权限设置”中进行自定义控制。<br/>
              支持批量设置权限。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell24/manuell24-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell24/manuell24-3.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell24/manuell24-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell24/manuell24-5.jpg'/>
        </div>
    )
  }
}

export default manuell24;