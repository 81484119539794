import React from 'react';
import ReactPlayer from 'react-player'

class manuell33 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>跑腿帮买店铺</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell33/manuell33.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell33/manuell33-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              什么是跑腿帮买店铺？<br/>
              跑腿平台里没有商家、不能上传商品，用户不能直接选购。<br/>
              但是通过“帮买店铺”这个功能，平台可以跟当地某些“网红店”达成合作，让商家开店卖东西，用户直接选购商品，让骑手帮买，简化跑腿下单流程，用户先支付“跑腿费”，待骑手配送完成后，线下再支付帮买物品的“商品费”。<br/><br/>
              帮买流程：选店铺帮买    →    支付跑腿费   →    骑手购买配送   →   支付商品费
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell33/manuell33-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell33/manuell33-3.png'/>
        </div>
    )
  }
}

export default manuell33;