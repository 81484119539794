import React from 'react';
import ReactPlayer from 'react-player'

class manuell607 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>会员积分</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell607/manuell607.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell607/manuell607-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统可自定义设置会员积分规则。<br/>
              用户可通过多种方式获得积分，也可通过多种方式使用"所获得"的积分，以此增加用户粘度。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell607/manuell607-2.png'/>
            <p>
              获得方式<br/>
              <span style={{fontWeight: 'bold'}}>消费返积分</span><br/>
              <span style={{fontWeight: 'bold'}}>充值赠送积分</span><br/>
              <span style={{fontWeight: 'bold'}}>签到获得积分</span><br/><br/>
              使用方式<br/>
              <span style={{fontWeight: 'bold'}}>消费使用积分</span><br/>
              <span style={{fontWeight: 'bold'}}>积分商城兑换设置的积分奖励（支持积分兑换优惠券，兑换其他物品）</span><br/>
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '190px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell607/manuell607-3.png'/>
        </div>
    )
  }
}

export default manuell607;