import React from 'react';
import ReactPlayer from 'react-player'

class manuell606 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>营销广告</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer  width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell606/manuell606.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell606/manuell606-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持在小程序内多处设置自定义”营销宣传图“，支持”静态展示“和”动态展示“。<br/>
              营销广告是提供给平台的广告位，包括了开屏广告、轮播广告、魔方广告以及弹窗广告，平台可以将这些广告位按照曝光程度提供给商家做广告营销，收取广告费用，作为平台的一项固定收益。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell606/manuell606-2.jpg'/>
        </div>
    )
  }
}

export default manuell606;