import React from 'react';
import ReactPlayer from 'react-player'

class manuell503 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>外卖商家自配送</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell503/manuell503.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell503/manuell503-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              商家自配送：即用户在小程序外卖下单后，订单不再由平台的骑手进行配送，而是由商家自行配送。<br/>
              在“商户管理”--“商户列表”--“选择商家、点击编辑”--“外卖设置”--“配送方式”中选择“商家自配送”。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell503/manuell503-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '260px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell503/manuell503-3.png'/>
        </div>
    )
  }
}

export default manuell503;