import React from 'react';
import ReactPlayer from 'react-player'

class manuell604 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>充值赠送</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell604/manuell604.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell604/manuell604-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持用户/商户参与充值余额赠送奖励。<br/>
              <span style={{fontWeight: 'bold'}}>充值赠送现金活动、充值赠送优惠券、充值赠送积分</span>
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell604/manuell604-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '310px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell604/manuell604-3.png'/>
        </div>
    )
  }
}

export default manuell604;