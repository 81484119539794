import React from 'react';

class manuell44 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>外卖抢单池</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              平台可创建外卖抢单池。创建时，选择相应骑手，并选择对应的商户，则用户外卖下单/商户下单后，此订单则会指定抢单池中，同时抢单池里的骑手则可接此单。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell44/manuell44-2.png'/>
            <p>
              外卖抢单池也可设置用户自主选择模式：即用户外卖下单/商户下单时，自己选择订单所发往的“抢单池”。可在“跑腿设置”--“抢单池配置”自定义设置抢单池内的骑手。
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell44/manuell44-3.png'/>
        </div>
    )
  }
}

export default manuell44;