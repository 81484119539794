import React from 'react';
import ReactPlayer from 'react-player'

class manuell12 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>骑手佣金</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell12/manuell12.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell12/manuell12-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              ①直接设置：可在“骑手资金设置”里“佣金设置”针对不同骑手类型及不同订单类型的骑手进行佣金设置，计费方式包含“按单计价”、“比例计价”、“里程计价”、“特殊日期奖励”、“骑手天气补贴”
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell12/manuell12-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell12/manuell12-3.png'/>
            <p> ②骑手等级：可设置”骑手等级“，针对不同等级的骑手设置不同的佣金计价方式。</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell12/manuell12-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell12/manuell12-5.png'/>
        </div>
    )
  }
}

export default manuell12;