import React, {useEffect} from 'react'
import { Carousel, Row, Col } from 'antd'
import CarouselIndex from '../../components/Carousel'
import Title from '../../components/Title'
import classnames from 'classnames'
import '../../css/takeaway.scss'
import '../../css/CampusPlatform.scss'
import { Helmet } from 'react-helmet';

const Carousel_text = [
  {
    carouselImage:
      'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_takeaway_banner_021.png',
    text: [
      { text: '比肩', colorText: '美团' , opposite:'true'},
      { text: '，打造独立的品牌化外卖平台'}
    ],
    desc: '领跑行业的技术水平，保姆式运营扶持',
    scenes: [{ text: '多终端支持，一站式搭建，低成本运营' }]
  }
]

const module5Arr = [
  {
    icon:
      'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/ic_case_about1.png',
    title: '商家入驻费',
    text:
      '针对每位入驻平台的商家，运营者可每收取商家平台入驻费，商家按年支付，形成平台固定收入产值。'
  },
  {
    icon:
      'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/ic_case_about1(1).png',
    title: '订单抽成',
    text:
      '邀请商户入驻外卖平台，与商家达成协议，就平台订单交易金额，按照比例抽取固定佣金。'
  },
  {
    icon:
      'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/ic_case_about1(2).png',
    title: '广告收益',
    text:
      '外卖平台内有开屏广告、魔方导航营销图、banner轮播等丰富广告位，定制投放方案，商家付费推广。'
  },
  {
    icon:
      'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/ic_case_about1(2).png',
    title: '配送抽成',
    text:
      '外卖平台打通配送系统，每单配送费用抽取一定比例或者固定配送佣金，多渠道获利。'
  },
  {
    icon:
      'https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/takeaway/ic_case_about1.png',
    title: '站点加盟',
    text:
      '多城市、多区域站点加盟，分站点页面独立配置，收益独立结算，增加平台固定收益。'
  }
]

const module6Arr = [
  {
    icon:'icon-moduleE1',
    title: '领先技术水平',
    text:'专注本地O2O系统相关领域开发，累积多年行业经验，提供完善的解决方案'
  },
  {
    icon:'icon-moduleE2',
    title: '资深开发团队',
    text:
      '多年软件工程师领队开发，产品、UI、开发、测试、运维一站式专业团队'
  },
  {
    icon:'icon-moduleE3',
    title: '数据安全保障',
    text:
      '平台数据严谨加密处理，数据信息独立安全，为客户做好安全护盾'
  },
  {
    icon:'icon-moduleE4',
    title: '专业运维技术',
    text:
      '资深的运维人员，定期服务日志排查与防护监控，阿里云服务服务器保障支撑'
  },
  {
    icon:'icon-moduleE5',
    title: '聆听客户建议',
    text:
      '积极听取客户前线意见，认真采纳有效建议并及时安排在开发排程中'
  },
  {
    icon:'icon-moduleE6',
    title: '多终端覆盖',
    text:'系统全套包含公众号H5、小程序、APP（Android+IOS）、PC后台，数据互通'
  },
  {
    icon:'icon-moduleE7',
    title: '多站点城市加盟',
    text:
      '支持开通不同城市/区域站点，分站独立设置，总站统一管理，站点自由切换'
  },
  {
    icon:'icon-moduleE8',
    title: '高效迭代更新',
    text:
      '专业SaaS服务，免费提供技术支持与运营维护，产品保持高频更新迭代'
  },
  {
    icon:'icon-moduleE9',
    title: '一对一售后服务',
    text:
      '匹配专人售后客服服务，5*12h一对一远程服务，在线解决用户问题'
  },
  {
    icon:'icon-moduleE10',
    title: '支持定制开发',
    text:
      '针对不同客户运营模式需求，可提供专属定制开发服务，定制特殊需求功能'
  }
]

let pageTimer = '' //停留时间

export default React.memo((props) => {
  const slider = React.createRef()
  const sliderA = React.createRef()
  const [plateIndexA, setPlateIndexA] = React.useState(0)
  const [plateIndexB, setPlateIndexB] = React.useState(0)

  useEffect(() => {
    // 获取进入页面的时间
    if (pageTimer === '') {
      pageTimer = parseInt(new Date().getTime() / 1000);
      console.log(pageTimer, 'pageTimer')
    }
    props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'take_out_food'
    }, 0)
  
    // 这个返回的函数，会在该组件卸载时来执行
    // 因此，可以去执行一些清理操作，比如，解绑 window 的事件、清理定时器 等
    return () => {
      // 离开时间减去进入时间
      const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
      console.log(tempTimer, 'tempTimer')
      // 清空时间戳
      pageTimer = '';
      if (tempTimer > 0) {
        props.onVisitClick({
          type: 2,
          domain: 'www.qolai.cn',
          page: 'take_out_food',
          time: tempTimer
        }, 0)
      }
    }
  }, []);

  const goToIndexA = index => {
    slider.current.slick.slickGoTo(index)
    setPlateIndexA(Number(index))
    setPlateIndexB(0)
  }
  const slickGoToIndex = index =>{
    sliderA.current.slick.slickGoTo(index)
    setPlateIndexB(Number(index))
    setPlateIndexA(0)
  }
  

  return (
    <div>
      <Helmet>
        <title>趣来达-外卖平台系统-餐饮外卖小程序-校园外卖</title>
        <meta name="Description" content="趣来达外卖平台系统可用于同城外卖、校园外卖等生活圈，打造类似美团独立品牌化的外卖平台；专注本地o2o系统领域，为外卖平台运营商提供完善的业务解决方案。" />
        <meta name="Keywords" content="外卖系统,同城外卖小程序,外卖平台" />
      </Helmet>
      {/**顶部banner**/}
      <CarouselIndex data={Carousel_text}  />
      {/* 为什么选择趣来达外卖系统 */}
      <div style={{marginTop:'80px'}}>
        <Title title="深挖外卖市场，满足多场景运营模式" 
               text="operation mode" 
               dec="随着外卖服务对日常生活的渗入，市场需求与日俱增，以城市为发展中心，不断向三四线城市、校园、园区等区域特征性地区下沉发展。趣来达针对不同运营需求，提供多场景创业机会"
               width={710}
               height={66} />
        <div className="d-flex justify-content-center">
          <div className="operating-model"  style={{ width: '1440px'}}>
            <div className="operating-model-box">
              <img alt="" src={require(`../../asset/takeOutFood/icon-moduleA1.png`)} style={{width:'100px',height:'100px',marginBottom:'27px'}}/>
                <p
                  style={{
                    fontSize: '24px',
                    color: '#121213',
                    lineHeight:'1',
                    marginBottom: '17px',
                    fontWeight: 'bold'
                  }}
                >同城外卖</p>
                <p 
                style={{
                    fontSize: '16px',
                    lineHeight:'25.6px',
                    color: '#707070',
                    marginBottom: '0px',
                  }}>
                面向城市区域类平台创业，针对中心城市与三四线城市、乡镇地区提供了不同风格化的模板类型，创业者可凭借自身的本地资源优势快速搭建独立的品牌化外卖平台，打造本地特色化</p>
                <div className="d-flex jsbet wrap" style={{marginTop:'40px'}}>
                  <div className="operating-model-box-tag">
                    多城市站点加盟
                  </div>
                  <div className="operating-model-box-tag">
                    免费维护迭代升级
                  </div>
                  <div className="operating-model-box-tag">
                    3-5天快速搭建
                  </div>
                  <div className="operating-model-box-tag">
                    一对一售后服务   
                  </div>
                </div>
                <div className="operating-model-box-more" onClick={() => 
                  props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.cn',
                    page: 'take_out_food',
                    button: 'more'
                  }, () => window.location.href = "/system_oto")
                  }>
                  查看更多
                </div>
            </div>
            <div className="operating-model-box">
              <img alt="" src={require(`../../asset/takeOutFood/icon-moduleA2.png`)} style={{width:'100px',height:'100px',marginBottom:'27px'}}/>
                <p
                  style={{
                    fontSize: '24px',
                    color: '#121213',
                    lineHeight:'1',
                    marginBottom: '17px',
                    fontWeight: 'bold'
                  }}
                >校园外卖</p>
                <p 
                style={{
                    fontSize: '16px',
                    lineHeight:'25.6px',
                    color: '#707070',
                    marginBottom: '0px',
                  }}>
                消费者与商家高度集中的特殊区域，全国各地均有潜力巨大的市场，客源稳定，需求量大，可联合高校餐厅及周边商家提供校内外餐饮外卖配送，也可以多校区分区加盟运营</p>
                <div className="d-flex jsbet wrap" style={{marginTop:'40px'}}>
                  <div className="operating-model-box-tag">
                    分校校区加盟
                  </div>
                  <div className="operating-model-box-tag">
                    分站校区独立设置
                  </div>
                  <div className="operating-model-box-tag">
                    低成本创业投入
                  </div>
                  <div className="operating-model-box-tag">
                    集中客源稳定收入   
                  </div>
                </div>
                <div className="operating-model-box-more" onClick={() => 
                  props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.cn',
                    page: 'take_out_food',
                    button: 'more'
                  }, () => window.location.href = "/campus_platform")
                  }>
                  查看更多
                </div>
            </div>
            <div className="operating-model-box">
              <img alt="" src={require(`../../asset/takeOutFood/icon-moduleA3.png`)} style={{width:'100px',height:'100px',marginBottom:'27px'}}/>
                <p
                  style={{
                    fontSize: '24px',
                    color: '#121213',
                    lineHeight:'1',
                    marginBottom: '17px',
                    fontWeight: 'bold'
                  }}
                >生活服务(不止同城)</p>
                <p 
                style={{
                    fontSize: '16px',
                    lineHeight:'25.6px',
                    color: '#707070',
                    marginBottom: '0px',
                  }}>
                基于传统外卖模式特点，延伸开发的全新外卖体系，突破同城局限，支持传统外卖、生鲜果蔬、美食特产、休闲娱乐等活动的到店消费、线下零售、物流配送等多种运营模式，完美融合线上线下，支持多门店和加盟店在等不同的运营模式</p>
                <div className="d-flex jsbet wrap" style={{marginTop:'40px'}}>
                  <div className="operating-model-box-tag">
                    本地商家入驻
                  </div>
                  <div className="operating-model-box-tag">
                    外卖配送+到店自提
                  </div>
                  <div className="operating-model-box-tag">
                    物流配送
                  </div>
                  <div className="operating-model-box-tag">
                    团购核销线下引流  
                  </div>
                  <div className="operating-model-box-tag" style={{width:'400px'}}>
                    本地帮办上门服务  
                  </div>
                </div>
                <div className="operating-model-box-more" onClick={() => 
                  props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.cn',
                    page: 'take_out_food',
                    button: 'more'
                  }, () => window.location.href = "/multi_platform")
                  }>
                  查看更多
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* 为什么选择趣来达外卖系统 */}
      <div style={{marginTop:'80px', paddingBottom:'80px', paddingTop:'80px',background:'#f8f8f8'}}>
          <Title title="多终端支持，标准化运作流程" 
                 text="operating process"
                 width={710}
                 height={66} />
          <Row>
            <Col sm={24} xl={24}>
              <div className="d-flex justify-content-center">
                <div className="d-flex" style={{ width: '1440px'}}>
                  <div 
                      className={ plateIndexB === 0 ? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onClick={() => {
                        slickGoToIndex(0);
                      }}
                      style={{width:'20%'}}
                  >
                    <div className='flex_column align-cen'>
                      <p style={{marginBottom:'16px',lineHeight:'1',textAlign:'center'}}>用户端</p>
                      <p style={{fontSize:'16px',lineHeight:'1',fontWeight:'normal',textAlign:'center'}}>微信、抖音、头条小程序+H5</p>
                    </div>
                  </div>
                  <div 
                      className={ plateIndexB === 1 ? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onClick={() => {
                        slickGoToIndex(1);
                      }}
                      style={{width:'20%'}}
                  >
                    <div className='flex_column align-cen'>
                      <p style={{marginBottom:'16px',lineHeight:'1',textAlign:'center'}}>商家</p>
                      <p style={{fontSize:'16px',lineHeight:'1',fontWeight:'normal',textAlign:'center'}}>APP</p>
                    </div>
                  </div>

                  <div 
                      className={ plateIndexB === 2 ? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onClick={() => {
                        slickGoToIndex(2);
                      }}
                      style={{width:'20%'}}
                  >
                    <div className='flex_column align-cen'>
                      <p style={{marginBottom:'16px',lineHeight:'1',textAlign:'center'}}>商家后台</p>
                      <p style={{fontSize:'16px',lineHeight:'1',fontWeight:'normal',textAlign:'center'}}>PC端</p>
                    </div>
                  </div>

                  <div 
                      className={ plateIndexB === 3 ? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onClick={() => {
                        slickGoToIndex(3);
                      }}
                      style={{width:'20%'}}
                  >
                    <div className='flex_column align-cen'>
                      <p style={{marginBottom:'16px',lineHeight:'1',textAlign:'center'}}> 骑手</p>
                      <p style={{fontSize:'16px',lineHeight:'1',fontWeight:'normal',textAlign:'center'}}>APP</p>
                    </div>
                  </div>

                  <div 
                      className={ plateIndexB === 4 ? "pages-activate pages-nonactivated" : "pages-nonactivated"}
                      onClick={() => {
                        slickGoToIndex(4);
                      }}
                      style={{width:'20%'}}
                  >
                    <div className='flex_column align-cen'>
                      <p style={{marginBottom:'16px',lineHeight:'1',textAlign:'center'}}>调度后台</p>
                      <p style={{fontSize:'16px',lineHeight:'1',fontWeight:'normal',textAlign:'center'}}>PC端</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={24} xl={24} style={{ marginTop: "47px" }}>
              <Carousel dec={false} ref={sliderA}>
                <div className='d-flex jscen'>
                  <div className="d-flex jsbet align-cen" style={{width:'1440px'}}>
                    <img alt="" src={require(`../../asset/takeOutFood/img-moduleB1.png`)} style={{width:'720px',height:'500px'}}/>
                    <div>
                      <div style={{fontSize:'30px',fontWeight:'bold',color:"#121213",marginBottom:'19px'}}>
                        用户端
                      </div>
                      <div style={{fontSize:'20px',color:"#707070",marginBottom:'60px'}}>
                        清晰便捷的下单入口，丰富多样的营销玩法，升级用户体验。
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 丰富外卖模板自定义选择
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 模块自由拖动，后台可视化操作配置
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持多平台入驻，微信、抖音、头条，紧跟时代潮流
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持外卖配送、物流配送、聚合配送、用户自提、团购到店
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 多城市区域加盟，站点自由切换
                      </div>
                      <div className="home-tags-more-button" onClick={() => 
                        props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'take_out_food',
                          button: 'consultation'
                        }, () => window.location.href = "https://wpa.qq.com/msgrd?v=3&uin=1115238556&site=qq&menu=yes")
                        }>
                        购买咨询
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex jscen'>
                  <div className="d-flex jsbet align-cen" style={{width:'1440px'}}>
                    <img alt="" src={require(`../../asset/takeOutFood/img-moduleB2.png`)} style={{width:'720px',height:'500px'}}/>
                    <div>
                      <div style={{fontSize:'30px',fontWeight:'bold',color:"#121213",marginBottom:'19px'}}>
                        商家APP：
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持多品牌小票/标签打印机连接
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 商户钱包余额充值，支持充值赠送
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持商家自动/手动接单，来单自动打印
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 多种营销折扣活动商家自定义设置
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 团购到店商品扫码核销
                      </div>
                      <div className="home-tags-more-button" onClick={() => 
                        props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'take_out_food',
                          button: 'consultation'
                        }, () => window.location.href = "https://wpa.qq.com/msgrd?v=3&uin=1115238556&site=qq&menu=yes")}>
                        购买咨询
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='d-flex jscen'>
                  <div className="d-flex jsbet align-cen" style={{width:'1440px'}}>
                    <img alt="" src={require(`../../asset/takeOutFood/img-moduleB3.png`)} style={{width:'720px',height:'500px'}}/>
                    <div>
                      <div style={{fontSize:'30px',fontWeight:'bold',color:"#121213",marginBottom:'19px'}}>
                        商家后台：
                      </div>
                      <div style={{fontSize:'20px',color:"#707070",marginBottom:'60px'}}>
                        便捷商户订单、商品、分类等设置，详细经营数据分析
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 商家基础信息设置，编辑操作
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 订单按需快速检索，快速回复用户评价
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 账户资金管理与明细，实时对账查账
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持商户订单、营销等数据导出
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 商家外卖经营数据分析，实时掌握方便库存核对
                      </div>
                      <div className="home-tags-more-button" onClick={() => 
                        props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'take_out_food',
                          button: 'consultation'
                        }, () => window.location.href = "https://wpa.qq.com/msgrd?v=3&uin=1115238556&site=qq&menu=yes")}>
                        购买咨询
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex jscen'>
                  <div className="d-flex jsbet align-cen" style={{width:'1440px'}}>
                    <img alt="" src={require(`../../asset/takeOutFood/img-moduleB4.png`)} style={{width:'720px',height:'500px'}}/>
                    <div>
                      <div style={{fontSize:'30px',fontWeight:'bold',color:"#121213",marginBottom:'19px'}}>
                        骑手APP：
                      </div>
                      {/* <div style={{fontSize:'20px',color:"#707070",marginBottom:'60px'}}>
                        清晰便捷的下单入口，丰富多样的营销玩法，升级用户体验。
                      </div> */}
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持实名认证与保证金缴纳
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 骑手奖惩制度设置与数据排行展示
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 支持设立骑手等级，规范平台管理
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 骑手保险功能，完善团队福利机制
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 骑手公告、骑手通知，提升平台管理骑手效率。
                      </div>
                      <div className="home-tags-more-button" onClick={() => 
                        props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'take_out_food',
                          button: 'consultation'
                        }, () => window.location.href = "https://wpa.qq.com/msgrd?v=3&uin=1115238556&site=qq&menu=yes")}>
                        购买咨询
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex jscen'>
                  <div className="d-flex jsbet align-cen" style={{width:'1440px'}}>
                    <img alt="" src={require(`../../asset/takeOutFood/img-moduleB5.png`)} style={{width:'720px',height:'500px'}}/>
                    <div>
                      <div style={{fontSize:'30px',fontWeight:'bold',color:"#121213",marginBottom:'19px'}}>
                        管理后台：
                      </div>
                      {/* <div style={{fontSize:'20px',color:"#707070",marginBottom:'60px'}}>
                        清晰便捷的下单入口，丰富多样的营销玩法，升级用户体验。
                      </div> */}
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 可视化DIY页面配置，精美模板一键使用
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 添加商户、骑手进入团队，设置权限
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 自定义设置平台计价方案与骑手佣金制度
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 平台财务数据化展示，拥有提现打款权限
                      </div>
                      <div style={{fontSize:'20px', marginBottom:'16px', color:'#333333',lineHeight:'50px' ,paddingLeft:"17px",width: '580px',height: '50px',background: '#FFFFFF',border: '1px dashed #E8E8E8',borderRadius: '6px'}}>
                        √ 自定义设置员工身份和管理权限，添加员工
                      </div>
                      <div className="home-tags-more-button" onClick={() => 
                        props.onVisitClick({
                          type: 3,
                          domain: 'www.qolai.cn',
                          page: 'take_out_food',
                          button: 'consultation'
                        }, () => window.location.href = "https://wpa.qq.com/msgrd?v=3&uin=1115238556&site=qq&menu=yes")}>
                        购买咨询
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </Col>
          </Row>
        </div>
      {/* 缜密逻辑体系，打造领先行业的标杆产品 */}
      <div style={{paddingBottom: 80, paddingTop: 80  }}>
        <Title
          title="强大的外卖平台营销功能体系"
          text="function system"
          width={710}
          height={66}
        />
        <div
          className="d-flex justify-content-center  align-items-center"
          style={{ marginTop: '50px', marginBottom: '76px' }}
        >
          <div
            onMouseEnter={() => {
              goToIndexA(0)
            }}
            style={{ width: '265px' }}
            className={classnames(
              'home-plate2-btn',
              'd-flex',
              'justify-content-center',
              'align-items-center',
              { 'home-plate2-btn-active': plateIndexA === 0 }
            )}
          >
            裂变拉新
          </div>
          <span className="home-plate2-span"></span>
          <div
            onMouseEnter={() => {
              goToIndexA(1)
            }}
            style={{ width: '265px' }}
            className={classnames(
              'home-plate2-btn',
              'd-flex',
              'justify-content-center',
              'align-items-center',
              { 'home-plate2-btn-active': plateIndexA === 1 }
            )}
          >
            提升复购
          </div>
          <span className="home-plate2-span"></span>
          <div
            onMouseEnter={() => {
              goToIndexA(2)
            }}
            style={{ width: '265px' }}
            className={classnames(
              'home-plate2-btn',
              'd-flex',
              'justify-content-center',
              'align-items-center',
              { 'home-plate2-btn-active': plateIndexA === 2 }
            )}
          >
            增加黏性
          </div>
          
        </div>
        <Carousel dots={false} ref={slider}>
          <div className="d-flex justify-content-center">
            <div className="d-flex jsbet" style={{width:'1440px',height:'400px'}}>
              <div className="d-flex flex_column jsbet">
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC1.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >邀请有礼</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>用户邀请用户、骑手邀请骑手、商家邀请商家、骑手邀请商家，邀请成功即可获得现金奖励</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC2.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >新客立减</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>可设置新客减免活动，促进大量新客户下单，提升平台用户裂变速度</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC3.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >新人优惠券</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>平台可设置专属新人的优惠券，新人才有权限领取</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC4.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >折扣商品</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>商家可设置少数优惠的折扣商品，低于市场的价格吸引更多用户下单，打造店铺爆款</p>
                  </div>
                </div>

              </div>

              <img  
                  alt=''
                  src={require(`../../asset/takeOutFood/img-moduleC1.png`)}
                  style={{
                  width: '600px',
                  height: '400px',
                  borderRadius: '10px',
                }}/>
            </div> 
          </div>
          
          <div className="d-flex justify-content-center">
            <div className="d-flex jsbet" style={{width:'1440px',height:'400px'}}>
              <div className="d-flex flex_column jsbet">
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC5.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >满减活动</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>设立营销门槛，用户下单需达到满减值即可享受平台减免优惠活动，可设置多个满减梯队</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC6.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >指定优惠券</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>发送优惠券给指定平台用户，限指定用户领取，可针对长时间没下单的用户定向营销</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC7.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >立减配送费</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>可设置配送费直接减免活动，不限制用户下单金额，下单即可免配送费，由商家或团队承担</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC8.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >定额减配送费</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>可设置有一定门槛的配送费减免，用户下单达成减免额度即可享受优惠。商家可设置少数优惠的折扣商品，低于市场的价格吸引更多用户下单，打造店铺爆款</p>
                  </div>
                </div>

              </div>

              <img  
                  alt=''
                  src={require(`../../asset/takeOutFood/img-moduleC2.png`)}
                  style={{
                  width: '600px',
                  height: '400px',
                  borderRadius: '10px',
                }}/>
            </div> 
          </div>


          <div className="d-flex justify-content-center">
            <div className="d-flex jsbet" style={{width:'1440px',height:'400px'}}>
              <div className="d-flex flex_column jsbet">
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC9.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >会员积分</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>团队自定义积分规则，下单返积分、充值赠送积分；下单抵扣，积分商城；形成积分获取消费闭环</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC10.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >会员红包</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>支持平台红包售卖功能，低价购买更大额的红包，参与活动的店铺均可接受消费</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC11.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >充值赠送</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>针对用户和商户可设置充值赠送功能，支持充值赠送现金、优惠券、积分</p>
                  </div>
                </div>
                <div className="d-flex">
                  <img alt="" src={require(`../../asset/takeOutFood/icon-moduleC12.png`)} style={{width:'60px',height:'60px',marginRight:"20px"}}/>
                  <div>
                    <p
                      style={{
                        fontSize: '24px',
                        color: '#121213',
                        lineHeight:'1',
                        marginBottom: '17px',
                        fontWeight: 'bold'
                      }}
                    >营销广告</p>
                    <p 
                    style={{
                        fontSize: '16px',
                        lineHeight:'1',
                        color: '#707070',
                        marginBottom: '0px',
                      }}>系统内多个页面均可设置弹窗广告，可给高品质、高性价比店铺多做营销宣传</p>
                  </div>
                </div>

              </div>

              <img  
                  alt=''
                  src={require(`../../asset/takeOutFood/img-moduleC3.png`)}
                  style={{
                  width: '600px',
                  height: '400px',
                  borderRadius: '10px',
                }}/>
            </div> 
          </div>

        </Carousel>
      </div>
      
      {/* 趣来达 · 获利渠道指导 */}
      <div style={{paddingTop:'80px'}}>
        <Title title="趣来达 · 获利渠道指导" text="excellent case" width={710} height={66} />
        <div className="d-flex justify-content-center">
          <div className="detaile-module5-box">
            {module5Arr.map(item => {
              return (
                <div className="detaile-module5-box-item" style={{width:264}}>
                  <div className="detaile-module5-box-item-top">
                    <div className="detaile-module5-box-item-top-radius">
                      <img alt=""
                        src={item.icon}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: '33px',
                      fontSize: '20px',
                      color: '#333333',
                      marginBottom: '23px',
                      fontWeight: 'bold'
                    }}
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#707070',
                      fontWeight:'600',
                      textAlign:'center'
                    }}
                  >
                    {item.text}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* 为什么选择趣来达外卖系统 */}
      <div>
        <Title title="为什么选择趣来达外卖系统" 
               text="reason to choose" 
               dec="专注本地O2O系统领域，多年的运营实战与开发服务经验，强大的技术开发团队，全方位产品功能优势，为外卖平台运营商提供完善的业务解决方案"
               width={710}
               height={66} />
        <div className="d-flex justify-content-center">
          <div className="detaile-module5-box"  style={{ width: '1440px',height:'620px', flexWrap:'wrap'}}>
            {module6Arr.map(item => {
              return (
                <div className="detaile-module5-box-item" style={{width:'264px',height:'270px'}}>
                  <img alt="" 
                        style={{
                          width: '60px',
                          height: '60px',
                        }}
                        src={require(`../../asset/takeOutFood/${item.icon}.png`)}/>
                  <div
                    style={{
                      marginTop: '20px',
                      fontSize: '20px',
                      color: '#333333',
                      marginBottom: '19px',
                      fontWeight: 'bold'
                    }}
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      color: '#707070',
                      fontWeight:'600',
                      textAlign:'center'
                    }}
                  >
                    {item.text}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

    </div>
  )
})
