import React from 'react';
import ReactPlayer from 'react-player'

class manuell34 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>跑腿抢单池</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell34/manuell34.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell34/manuell34-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              平台可创建跑腿抢单池。创建时，选择相应骑手，并在跑腿设置处选择场景作为抢单池，则用户在此跑腿场景下单后，此订单则会指定抢单池中，同时抢单池里的骑手则可接此单。跑腿抢单池也可设置用户自主选择模式：即用户跑腿下单时，自己选择订单所发往的“抢单池”。<br/>
              可在“跑腿设置”--“抢单池配置”自定义设置抢单池内的骑手。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell34/manuell34-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell34/manuell34-3.jpg'/>
        </div>
    )
  }
}

export default manuell34;