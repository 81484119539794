import React from "react";
import { Row, Col, Icon, message } from "antd";
import { saveOfficialFrom } from "../apis/App";

import ma from '../asset/ma.png';
import wei from '../asset/wei.png';
import dou from '../asset/dou.png';
import ma3 from '../asset/ma3.png';
import tou from '../asset/tou.png';
import ma2 from '../asset/ma2.png';
import ma4 from '../asset/ma4.png';
const style = {
  fontSize: "12px",
  color: "#AEAFB4",
  marginRight: 5
};
const footer = {
  minWidth: "1300px",
  minHeight: 60,
  background: "linear-gradient(90deg,rgba(17,17,18,1),rgba(51,51,51,1))",
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000
};
const inputStyle = {
  width: 300,
  height: "2.6rem",
  padding: "0 0.5rem",
  border: "1px solid #ccc",
  borderRadius: "0.2rem",
  outline: "none"
};
const buttonStyle = {
  height: "2.5rem",
  margin: "0 0 0 0.5rem",
  width: "8.75rem",
  background: "#f6bb07",
  borderRadius: "1.25rem",
  lineHeight: "2.5rem",
  border: "none",
  fontSize: "1.2rem",
  fontWeight: 600,
  outline: "none",
  cursor: "pointer",
  color: "#333333"
};
const links = [
  {
    name: "壹立科技",
    link: "http://www.yiliit.com/"
  },
  {
    name: '趣来达外卖+跑腿系统',
    link: 'http://www.qolai.com/'
  }, {
    name: '壹脉销客AI智能名片',
    link: 'http://www.yimaiai.com/'
  }, {
    name: '壹脉销客智能CRM系统',
    link: 'http://www.yimaiai.cn/'
  }
];

let config = {}
if (typeof window !== "undefined") {
  config = window.sessionStorage.getItem('config')
    ? JSON.parse(window.sessionStorage.getItem('config')) : {};
}

class Footer extends React.PureComponent {
  state = {
    phone: "",
    isClose: true
  };

  subimt = async () => {
    let btn = document.getElementById("btn1");
    btn.disabled = true;
    const phoneExp = /^1[3456789]\d{9}$/;
    if (!this.state.phone) {
      message.error("请输入手机号");
      return;
    }
    if (this.state.phone === "" || !phoneExp.test(this.state.phone)) {
      message.error("请输入正确的手机号码");
      return;
    }
    const result = saveOfficialFrom(5, this.state.phone);
    console.log(result);
    message.success("提交成功!");
    this.refs.inputRef.value = ''
    this.state.phone = ''
    setTimeout(function () {
      btn.disabled = false;

    }, 3000);
  };

  render() {
    return (
      <div>
        <div style={{ background: "#333333" }}>
          <div className="containers" style={{ paddingBottom: "29px" }}>
            <Row style={{ margin: "0 auto" }}>
              <Col span={11} className="d-flex justify-content-start" style={{ marginTop: "79px" }}>
                <div className="text-left px-3">
                  <span className="text-white d-block" style={{ fontSize: "14px", lineHeight: "31px", fontWeight: 700 }}>
                    咨询热线：{config?.footer?.hot_line || '028-63853204'}
                  </span>
                  <div style={{ marginTop: "20px" }}>
                    <Icon type="phone" style={{ color: "#fff" }} />
                    <span className="text-white ml-2">{config?.footer?.phone || '17380144191 18081916514'}</span>
                    {/* <span className="text-white ml-2">18081916514</span> */}
                  </div>
                  <div style={{ marginTop: "19px" }}>
                    <Icon type="mail" style={{ color: "#fff" }} />
                    <span className="text-white ml-3">{config?.footer?.email || 'yiliit@163.com'}</span>
                  </div>
                  <div style={{ marginTop: "19px" }}>
                    <Icon type="home" style={{ color: "#fff" }} />
                    <span className="text-white ml-2">{config?.footer?.address || '成都高新区新裕路501号博雅城市广场'}</span>
                  </div>
                </div>
              </Col>
              <Col span={13} style={{ marginTop: "79px" }}>
                <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                  <div className="text-center d-inline-block" style={{ width: "16%" }}>
                    <span
                      className="text-white text-center d-block"
                      style={{ fontSize: "14px", lineHeight: "31px", marginBottom: "24px" }}
                    >
                      官方微信
                    </span>
                    <img
                      alt="img"
                      src={ma}
                      width="72"
                      height="72"
                    />
                    {/* < img alt="img" src={require('../asset/foooter_code.png')}  width="72" height="72" style={{marginTop: 20}}/> */}
                  </div>
                  <div className="d-inline-block" style={{ width: "16%" }}>
                    <span
                      className="text-white text-center d-block"
                      style={{ fontSize: "14px", lineHeight: "31px", marginBottom: "24px" }}
                    >
                      关注我们
                    </span>
                    <div className="text-center footer-hover">
                      <img src={wei} alt="" width="30px" />
                      <img
                        alt="img"
                        src={ma3}
                        className="footer-hover-img"
                      />
                    </div>
                    <div className="text-center footer-hover mt-3">
                      <img src={tou} alt="" width="30px" />
                      <img
                        alt="img"
                        src={ma2}
                        className="footer-hover-img"
                      />
                    </div>
                    <div className="text-center footer-hover mt-3">
                      <img src={dou} alt="" width="30px" />
                      <img
                          alt="img"
                          src={ma4}
                          className="footer-hover-img"
                      />
                    </div>
                  </div>
                  <div className="d-inline-block float-left" style={{ width: '16%' }}>
                    <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>关于我们</span>
                    <a href="http://www.yiliit.com" className="text-center d-block" style={{ fontSize: '12px', color: '#AEAFB4' }}>关于壹立</a>
                    <span onClick={this.props.showDialog} className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4',cursor: 'pointer'}}>加入我们</span>
                  </div>
                  <div className="d-inline-block float-left" style={{ width: '22%' }}>
                    <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>相关产品</span>
                    <a href="http://www.yimaiai.cn" className="text-center d-block" style={{ fontSize: '12px', color: '#AEAFB4' }}>壹脉销客AI智能名片</a>
                    <a href="http://www.qolai.com/food" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>趣来达外卖系统</a>
                    <a href="/errands" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>趣来达跑腿系统</a>
                    <a href="/" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>县城外卖跑腿系统</a>
                    <a href="/" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>乡镇外卖跑腿系统</a>
                    <a href="/" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>校园外卖跑腿系统</a>
                    <a href="/take_out_food" className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>外卖配送系统</a>
                  </div>
                  <div className="d-inline-block float-left" style={{ width: '30%' }}>
                    <span className="text-white text-center d-block" style={{ fontSize: '14px', lineHeight: '31px', marginBottom: '24px' }}>售后服务</span>
                    <span className="text-center d-block" style={{ fontSize: '12px', color: '#AEAFB4' }}>服务时间: {config?.footer?.after_sale?.time}</span>
                    <span className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>公司专线: {config?.footer?.after_sale?.company_special_line}</span>
                    <span className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>售后专线: {config?.footer?.after_sale?.after_sales_special_line}</span>
                    <span className="text-center d-block mt-2" style={{ fontSize: '12px', color: '#AEAFB4' }}>友链合作微信: {config?.footer?.after_sale?.cooperation_wechat}</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <a style={{ marginLeft: '1rem', display: 'inline-block', marginTop: 35 }} href="tencent://message/?uin=1115238556&amp;Site=有事Q我&amp;Menu=yes">
                <button
                  style={{
                    height: "24px",
                    background: "#FFFFFF",
                    borderRadius: "12px",
                    fontSize: '12px',
                    fontWeight: 400,
                    color: '#383838'
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img
                    src="https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/social-qq.png"
                    style={{ marginRight: "8px", width: 20, height: 20 }}
                    alt=''
                  />
                  <span>在线咨询</span>
                </button>
              </a>
            </Row>
          </div>
        </div>
        <Row style={{ marginBottom: this.state.isClose ? 60 : 0 }}>
          <Col span={24} className="d-flex justify-content-center" style={{ background: "rgba(51,51,51,0.95)" }}>
            <span style={{ fontSize: "12px", lineHeight: "41px", color: "#AEAFB4" }}>
              © 2019-2024 qolai.com 成都壹立科技有限公司 版权所有&nbsp;&nbsp;电信经营许可证：
              <a
                href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%E5%B7%9DB2-20190570&code=63bm2&type=xuke"
                target="_blank"
              >
                川B2-20190570
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a href="http://beian.miit.gov.cn/" target="_blank">
                蜀ICP备19002747号-5
              </a>
            </span>
          </Col>
        </Row>
        {this.state.isClose && (
          <div style={footer}>
            <p className="m-0" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "#FFFFFF" }}>
              如果您有意向了解我们的系统，请提交信息，我们会尽快与您联系
            </p>
            <div style={{ marginLeft: 50 }}>
              <input ref='inputRef' style={inputStyle} onChange={e => this.setState({ phone: e.currentTarget.value })} />
              <button id="btn1" style={buttonStyle} onClick={() => {
                  this.props.onVisitClick({
                    type: 3,
                    domain: 'www.qolai.cn',
                    page: 'footer',
                    button: 'submit'
                  },0);
                  this.subimt()
                }}>
                提交
              </button>
            </div>
            <div style={{ position: "absolute", right: 22 }}>
              <Icon type="close" style={{ fontSize: 30 }} onClick={() => this.setState({ isClose: false })} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Footer;
