/**
 * @FileDescription: 关于壹立
 * @Author: Versient
 * @Date: 2024-09-10 16:33:27
 * @LastEditors: Versient
 * @LastEditTime: 2024-09-29 19:04:19
 */
"use client";
import React, { useState } from 'react'
// import Image from "next/image"
import Title from "../../../../components/Title_2";
import { about } from './menuData'

// 抬头标题样式
const englishStyle = { left: '66px', top: '38px', fontSize: '36px' }
const indicatorStyle = { left: '-2px' }
const defaultCard = {
  // background: 'linear-gradient(-90deg, #FFCC0C, #F5AD01)',
  background: '#FFCC0C',
  boxShadow: '0px 8px 20px 0px rgba(245, 173, 1, 0.4)',
}
const aboutCardFont = { fontFamily: 'Source Han Sans CN', fontWeight: 'bold' }

const AboutCompany = () => {
  const [aboutCur, setAboutCur] = useState(1)
  const [hoverState, setHoverStater] = useState(false)

  return (
    <div className='middle-container flex flex-nowrap justify-between overflow-x-hidden'>
      <div className={`pb-30`}>
        <div className='w-1/5'>
          <Title
            baseInfo={{
              title: "关于壹立",
              englishAlias: "ABOUT",
              englishStyle,
              indicatorStyle,
            }}
          />
        </div>
        <p className={`w-[770px] m-0 h-[175px] ${hoverState && 'animate__animated animate__fadeInLeft'}`} dangerouslySetInnerHTML={{
          __html: about.bannerCard[aboutCur - 1].detail
        }} />

        <div className='mt-[85px] flex justify-between bg-fff'>
          {about.bannerCard.map((item) => (
            <div className='w-[238px] py-[10px] box-border bg-[#F8F8F8] rounded-[10px] cursor-pointer hover:scale-[1.03] transition-all duration-[.8s] overflow-hidden' key={item.id}
              style={item.id === aboutCur ? { ...defaultCard } : {}} 
              onMouseEnter={() => {setAboutCur(item.id); setHoverStater(true)}}
              onMouseLeave={() => setHoverStater(false)}>
              <div className={`ml-[23px] text-[28px] animate__animated
                ${item.id === aboutCur ? 'animate__rotateInDownLeft text-[#FFFFFF]' : 'text-[#E8E8E8]'}`} style={{ ...aboutCardFont }}>{item.step}</div>
              <div className={`ml-[25px] mt-[10px] text-[32px] animate__animated 
                ${item.id === aboutCur && 'animate__bounceInRight'}`} style={{ ...aboutCardFont }}>{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className='mt-[155px] overflow-x-hidden'>
        <img className={`${hoverState && 'animate__animated animate__fadeInRight'}`} src={about.bannerCard[aboutCur - 1].icon} alt="pic" width="555px" height="340px" />
      </div>
    </div>
  )
}

export default AboutCompany

