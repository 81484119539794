import React from 'react';
import ReactPlayer from 'react-player'

class manuell508 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>第三方平台配送</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell508/manuell508.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell508/manuell508-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              第三方平台配送：即用户在小程序外卖下单后，订单不再由平台的骑手进行配送，全部发往第三方配送平台进行配送在“商户管理”--“商户列表”--“选择商家、点击编辑”--“外卖设置”--“配送方式”中选择“第三方平台配送”。
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell508/manuell508-2.png'/>
        </div>
    )
  }
}

export default manuell508;