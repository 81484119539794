import React from 'react';
import ReactPlayer from 'react-player'

class manuell605 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>邀请有礼</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持<span style={{fontWeight: 'bold'}}>"用户邀请新用户"、"骑手邀请新骑手"、"骑手邀请新商家"、"商家邀请新商家"。</span>一旦满足邀请条件，即可获得现金奖励。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605-2.jpg'/>
            <div className='d-flex justify-content-between align-items-center' style={{padding: '0 40px'}}>
              <img style={{ display: 'block' ,marginBottom: '40px',width: '310px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605-3.png'/>
              <img style={{ display: 'block' ,marginBottom: '40px',width: '310px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605-4.jpg'/>
            </div>
            <div className='d-flex justify-content-between align-items-center' style={{padding: '0 40px'}}>
              <img style={{ display: 'block' ,marginBottom: '0px',width: '310px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605-5.jpg'/>
              <img style={{ display: 'block' ,marginBottom: '0px',width: '310px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell605/manuell605-6.jpg'/>
            </div>
        </div>
    )
  }
}

export default manuell605;