import React from 'react';
import ReactPlayer from 'react-player'

class manuell43 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>打烊预约下单</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell43/manuell43.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell43/manuell43-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              店铺打烊后，用户也可提前预约下单。<br/>
              在“商户管理”--“商户列表”--“选择商家、点击编辑”--“外卖设置”--“预订单时间设置”中开启“店铺休息时，支持提前预订营业时间的订单”
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell43/manuell43-2.jpg'/>
        </div>
    )
  }
}

export default manuell43;