import React from 'react';
import ReactPlayer from 'react-player'

class manuell32 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>跑腿场景设置</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell32/manuell32.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell32/manuell32-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              在“跑腿设置”--“跑腿场景”中配置小程序跑腿场景，页面内容及组件都支持自定义设置。<br/>
              <span style={{fontWeight: 'bold'}}>组件设置</span>：系统支持“取”、“送”地址组件(包含姓名、电话、地址)、订单内容、订单总价、备注、送达时间、小费、地图展示、文本信息、上传图片、单项选择、多项选择、选择时间、购买地址、时间区间、保温箱等组件。
            </p>
            <div className='d-flex align-items-center'>
              <img style={{ display: 'block' ,marginBottom: '40px',width: '400px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell32/manuell32-2.jpg'/>
              <img style={{ display: 'block' ,marginBottom: '40px',width: '400px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell32/manuell32-3.png'/>
            </div>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell32/manuell32-4.jpg'/>
            <p>
              <span style={{fontWeight: 'bold'}}>计费方案</span>：系统支持”里程计价“、”区间计价“、”组合计价“、”范围计价“、”重量计价“、“预设费用”、“选择附加”、“时段附加”“天气附加”“物品报价”等计费方案
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell32/manuell32-5.jpg'/>
        </div>
    )
  }
}

export default manuell32;