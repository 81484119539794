export const getURLParameters = url => {
  if(url.indexOf('?') === -1) return {}
  return url.match(/([^?=&]+)(=([^&]*))/g).reduce((a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a), {})
}

// 配置请求
const prefixUrl = "https://static.yiliit.com";

export async function fetchConfig() {
  try {
    const response = await fetch(`${prefixUrl}/official_web/config.json`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error("请求失败");
    }
    return await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
}