import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Dialog from 'element-react/dist/npm/es6/src/dialog';
import 'element-theme-default';
import { Select, message, Spin  } from 'antd';


// apis
import { submit, visitClick } from './apis/App';
import { fetchConfig } from "./utils";

//  components
import Header from './components/Header';
import Footer from './components/Footer';
import Side from './components/Side';
import axios from 'axios';
import "animate.css";

import Home from './containers/index/Home';
import Errands from './containers/index/errands';
import Customized from './containers/index/Customized';
import CaseList from './containers/index/case_list';
import Information from './containers/index/Information';
import About from './containers/index/About/About';
import InformationDetail from './containers/index/information_detail';
import TakeOutFood from "./containers/index/take_out_food";
import DispatchSystem from "./containers/index/Dispatch_system";
import DownloadCenter from "./containers/index/DownloadCenter";
import Service from "./containers/index/Service";
import MultiPlatform from "./containers/index/MultiPlatform";
import SystemOto from "./containers/index/SystemOto";
import CampusPlatform from "./containers/index/Campus_platform";
import Manuell from './containers/index/manuell';
import Self from './containers/index/self';



const { Option } = Select;

const options = [{
  value: '1',
  label: '案例 演示及产品资料'
}, {
  value: '2',
  label: '详细报价'
}, {
  value: '3',
  label: '其他需求'
}]

class App extends React.Component {
  state = {
    isShowDialog: false,

    name: '',
    intention: '1',
    phone: '',
    weChatNum: '',
    isShow: true,
    headShow: true
  }
  
  componentDidMount() {
    message.config({
      top: 100,
      duration: 2
    });
    this.configRequest();
  }

  configRequest = async () => {
    try {
      const result = await fetchConfig();
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem('config', JSON.stringify(result))
      }
    } catch (error) {
      console.error("接口异常: ", error);
    }
  }

// 官网访问点击
 onVisitClick = async(data, onSuccess) => {
  let hide = null
  if (onSuccess !== 0) {
    hide = message.loading('', 0)
  }
  const parameter = {
    ...data
  }
  const res = await visitClick(parameter)
  hide && hide();
  if (res.code !== 200) {
    // message.error('请重试！')
    return;
  }
  onSuccess && onSuccess();
}

  onShow = (e) => {
    this.setState({
      isShow: e
    });
  }

  onHeadShow = (e) => {
    this.setState({
      headShow: e
    });
  }


  showDialog = () => {
    this.setState({
      isShowDialog: true
    });
  };

  submitForm = async (dialogFormVisible) => {
    const phoneExp = /^1[3456789]\d{9}$/;
    const {name, intention, phone, weChatNum} = this.state;
    if (name === '') {
      message.error('请正确填写姓名');
      return
    }

    if (intention === '') {
      message.error('请选择意向');
      return
    }

    if (phone === '' || !phoneExp.test(phone)) {
      message.error('请输入正确的手机号码');
      return
    }

    if (weChatNum === '') {
      message.error('请输入正确的微信号码');
      return
    }

    const result = await submit(name, intention, phone, weChatNum)
    console.log(result)
    if (result.code === 200) {
      message.success('提交成功！')
      this.setState({
        isShowDialog: false
      });
    } else {
      message.error('提交失败！')
      this.setState({
        isShowDialog: false
      });
    }
  }

  render() {
    return (
      <Router>
        <Header {...this.props} showDialog={this.showDialog} onShow={this.onShow} headShow={this.state.headShow} onHeadShow={this.onHeadShow} onVisitClick={this.onVisitClick}/>
        <Switch>
          <Route exact path="/" render={() => <Home {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>} />
          <Route exact path="/errands" render={() => <Errands {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/Customized" render={() => <Customized {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/case_list" render={() => <CaseList {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/Information" render={() => <Information {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/About" render={() => <About {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/information_detail" render={() => <InformationDetail {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/take_out_food" render={() => <TakeOutFood {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/dispatch_system" render={() => <DispatchSystem {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/download_center" render={() => <DownloadCenter {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/service" render={() => <Service {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/multi_platform" render={() => <MultiPlatform {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/system_oto" render={() => <SystemOto {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/campus_platform" render={() => <CampusPlatform {...this.props} showDialog={this.showDialog} onVisitClick={this.onVisitClick}/>}/>
          <Route exact path="/manuell" render={() => <Manuell {...this.props} onShow={this.onShow} onHeadShow={this.onHeadShow} onVisitClick={this.onVisitClick} />}/>
          <Route exact path="/self" render={() => <Self {...this.props} onShow={this.onShow} onHeadShow={this.onHeadShow} onVisitClick={this.onVisitClick} />}/>
        </Switch>
        <Side showDialog={this.showDialog}/>
        {
          this.state.isShow && <Footer showDialog={this.showDialog} onVisitClick={this.onVisitClick} />
        }
        <Dialog
          visible={this.state.isShowDialog}
          
          onCancel={() => {this.setState({isShowDialog: !this.state.isShowDialog})}}
          size="tiny"
        >
          <Dialog.Body style={{position:'relative'}}>
            <div>
              <h5 className="form-title">申请试用</h5>
              <div className="form-contacts ">
                <label>联系人</label>
                <input type="text"  placeholder="请填写您的姓名" value={this.state.name} onChange={(e) => {this.setState({name: e.currentTarget.value})}}/>
              </div>
              <div className="form-contacts">
                <label>您的需求</label>
                <Select defaultValue="请选择" value={this.state.intention} onChange={value => {this.setState({intention: value})}}>
                  {
                    options.map((item, index) => {
                      return (
                        <Option key={index} value={item.value}>{item.label}</Option>
                      )
                    })
                  }
                </Select>
              </div>
              <div className="form-contacts ">
                <label>手机号码</label>
                <div>
                  <span style={{fontWeight: '500', marginRight: 10}}>+86</span>
                  <input maxLength="11" type="text"  placeholder="请填写您的手机号码" value={this.state.phone} onChange={(e) => {this.setState({phone: e.currentTarget.value})}}/>
                </div>
              </div>
              <div className="form-contacts ">
                <label>微信</label>
                <input type="text"  placeholder="请填写微信号码" value={this.state.weChatNum} onChange={(e) => {this.setState({weChatNum: e.currentTarget.value})}}/>
              </div>
              <button className="form-btn-OnTrial" onClick={(dialogFormVisible) => {this.submitForm(dialogFormVisible)}}>立即领取</button>
              <div style={{marginTop: '10px',textAlign: 'center'}}>很着急 立即拨打或加V:18771040879</div>
              <div className={` ${this.state.isShowDialog === true ?  'loading-box-none' : 'loading-box'}`}>
                <Spin size="large" />
              </div>
            </div>
          </Dialog.Body>
        </Dialog>
      </Router>
    );
  }
}

export default App;
