import axios from 'axios';

export async function userSubmit(name, email, phone, remark) {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('email', email);
  formData.append('phone', phone);
  formData.append('remark', remark);
  const result = axios.post('/api/Official/saveOfficialContact', formData).catch(result => result)
  return result
}