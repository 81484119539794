import React from 'react';
import ReactPlayer from 'react-player'

class manuell05 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>资金管理</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer  width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell05/manuell05.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell05/manuell05-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p style={{fontWeight: 'bold'}}>资金管理</p>
            <p>
              系统支持团队手动自定义增减用户、骑手、商家的资金。在“财务结算”--“骑手资金”、“商户资金”和“用户资金”中可自定义编辑增减资金。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell05/manuell05-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell05/manuell05-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell05/manuell05-4.jpg'/>
            <p style={{fontWeight: 'bold'}}>提现操作</p>
            <p>
              系统支持“自动提现”和“手动打款”两种方式。<br/>
              自动提现：即申请提现后，点击打款，提现金额自动从微信商户号打款到提现的微信账户<br/>
              手动打款：即申请提现后，点击打款，需团队对提现时所提交的收款二维码进行手动扫码支付打款<br/>
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell05/manuell05-5.jpg'/>
        </div>
    )
  }
}

export default manuell05;