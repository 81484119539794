import React from 'react';
import ReactPlayer from 'react-player'

class manuell21 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>商家入驻</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              ①系统添加：支持后台输入手机号添加商家，也可通过“商家邀请链接”、“商家邀请有礼”和小程序“商家入驻”进行商家入驻<br/><br/>
              ②商家也可在“商家端”APP进行注册登录入驻平台。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21-5.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '700px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell21/manuell21-6.jpg'/>
        </div>
    )
  }
}

export default manuell21;