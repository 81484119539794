import React from 'react';
import ReactPlayer from 'react-player'

class manuell510 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>扫码接单</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell509/manuell509.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell509/manuell509-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              骑手可通过扫描小票上的订单二维码进行扫码接单。<br/>
              骑手权限设置：在“骑手管理”--“骑手信息”--“选择骑手、点击编辑”--“权限-选中“扫码接单权限””
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell510/manuell510-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '260px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell510/manuell510-3.png'/>
        </div>
    )
  }
}

export default manuell510;