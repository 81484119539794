import React from 'react';
import ReactPlayer from 'react-player'

class manuell608 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>分享有礼</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell608/manuell608.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell608/manuell608-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              分享有礼是指，用户将你的小程序分享给其他人，达到你分享规则上的目标人数，即可获得相应的奖励。<br/>
              在趣来达系统里，平台运营者可以在营销管理模块的分享有礼页面，设置活动的相关信息，包括活动时间、奖品类型、触发和奖励条件，以及分享人数、分享用户条件等等。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell608/manuell608-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '310px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell608/manuell608-3.jpg'/>
        </div>
    )
  }
}

export default manuell608;