import React from 'react';
import ReactPlayer from 'react-player'

class manuell603 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>外卖营销活动</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell603/manuell603.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell603/manuell603-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持多种类型外卖营销活动
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>减配送费：</span>用户下单可享受配送费减免优惠，支持“直接立减”和“定额满减”<br/>
              直接立减：符合条件的店铺内下单，每一笔订单都可减免配送费。<br/>
              定额满减：用户符合条件的店铺内下单，需订单达到设置的金额才可减配送费。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell603/manuell603-2.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>新客立减：</span>用户消费时满足满减门槛金额则可直接减免部分金额。<br/>
              门店新客立减：新用户在每个商家(参加了活动的商家)首次下单可享受此优惠。店铺内只能享受一次。<br/>
              平台新客立减：新用户在平台内(参加了活动的商家里)首次下单可享受此优惠，平台内只可享受一次。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell603/manuell603-3.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>满减活动：</span>用户初次下单可享受减免优惠
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell603/manuell603-4.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>售卖红包：</span>多张满减券组合售卖，提升下单频次，用户购买红包后，消费时可直接使用此红包。
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell603/manuell603-5.png'/>
        </div>
    )
  }
}

export default manuell603;