import React from 'react';
import ReactPlayer from 'react-player'

class manuell04 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>配送范围与营业时间</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer  width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell04/manuell04.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell04/manuell04-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p style={{fontWeight: 'bold'}}>平台配送范围</p>
            <p>
              分为“用户跑腿下单-配送范围”和“商户手动下单/用户外卖下单--配送范围”。<br/><br/>
              用户跑腿下单：指用户在小程序进行“跑腿订单”下单时的配送范围，超出此配送范围，用户跑腿则无法下单<br/>
              商户手动下单/用户外卖下单：指用户在小程序进行“外卖订单”下单和商户在商家端APP进行“手动录单”时的配送范围，超出此配送范围，则用户和商户无法下单。
            </p>
            <p style={{fontWeight: 'bold'}}>商户外卖配送范围</p>
            <p>
              指用户在小程序进行“外卖订单”下单的配送范围，如超出此配送范围，则用户则无法下单
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell04/manuell04-2.jpg'/>
            <p style={{fontWeight: 'bold'}}>平台营业时间</p>
            <p>
              分为“跑腿营业时间”和“外卖/商户下单营业时间设置”<br/>
              跑腿营业时间：指用户在小程序进行“跑腿订单”下单时的营业时间，不在此“营业时间”范围内，用户跑腿则无法下单<br/>
              外卖/商户下单营业时间设置：指用户在小程序进行“外卖订单”下单和商户在商家端APP进行“手动录单”时的营业时间，不在此“营业时间”范围内，则用户和商户无法下单。
            </p>
            <p style={{fontWeight: 'bold'}}>商户营业时间</p>
            <p>
            指店铺的营业时间，不在店铺的“营业时间”范围内，则用户无法外卖下单，商户无法手动下单。
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell04/manuell04-3.jpg'/>
        </div>
    )
  }
}

export default manuell04;