// 'use client'; 指令确保这个组件是 Client Component
"use client";
import React, {useEffect, useState} from "react";

function Title({baseInfo: {size, title, englishAlias, titleStyle, englishStyle, indicatorStyle, wordStyle}}) {
    const [titleFontSize, setTitleFontSize] = useState(36);

    useEffect(() => {
        const screenAvailWidth = window.innerWidth;
        setTitleFontSize(
            screenAvailWidth > 1200
                ? 36
                : screenAvailWidth > 768 && screenAvailWidth <= 1200
                    ? 24
                    : 14,
        );
    }, []);

    const wordArtStyle = () => {
        return {
            zIndex: 2,
            position: "relative",
            color: "#121213",
            fontWeight: 700,
            letterSpacing: 1,
            fontSize: titleFontSize,
            ...wordStyle
        };
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                marginTop: 40,
                marginBottom: 40,
                height: 64,
                fontFamily: "Source Han Sans CN",
                ...titleStyle,
            }}>
      <span
          style={{
              fontWeight: 700,
              color: "#E8E8E8",
              position: "absolute",
              zIndex: 1,
              top: size ? "auto" : "15px",
              bottom: size ? "-24px" : "auto",
              letterSpacing: .5,
              right: size ? "-15%" : "auto",
              fontSize: size === "small" ? titleFontSize : titleFontSize * 1.66,
              ...englishStyle
          }}>
        {englishAlias}
      </span>
            <div style={wordArtStyle()}>{title}</div>
            <div
                style={{
                    width: "40px",
                    position: "absolute",
                    left: size ? 0 : "auto",
                    bottom: -14,
                    zIndex: 2,
                    height: "6px",
                    background: "linear-gradient(to right, #FFCC0C 100%, #F5AD01 100%)",
                    boxShadow: '0px 8px 20px 0px rgba(245,173,1,0.4)',
                    borderRadius: '3px',
                    ...indicatorStyle
                }}></div>
        </div>
    );
}

export default Title;
