import React from 'react';
import ReactPlayer from 'react-player'

class manuell14 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>骑手管理</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell14/manuell14.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell14/manuell14-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              如何更好的对骑手进行高效管理<br/>
              ①设置骑手保证金，骑手需缴纳保证金才可正常接单，在“骑手管理”--“骑手资金设置”--“保证金设置”中设置
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell14/manuell14-2.png'/>
            <p> ②设置相关“奖惩制度”，在“骑手管理”--“考核奖惩制度”中可设置“奖励制度”和“惩罚制度”</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell14/manuell14-3.png'/>
            <p> ③设置相关“骑手排班”，骑手排班：骑手可根据设置的时间段，自主选择参与排班，并可获得相应奖励或惩</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell14/manuell14-4.png'/>
            <p>④设置骑手“接单”设置及“取送距离”限制。在“骑手管理”--“接单设置”/“取送设置”中设置。</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell14/manuell14-5.png'/>
            <p>⑤发布骑手公告，即使通知平台内骑手相关信息资讯。在“骑手管理”--“骑手公告”中设置。</p>
        </div>
    )
  }
}

export default manuell14;