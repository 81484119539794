import React from 'react';
import ReactPlayer from 'react-player'

class manuell13 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>骑手等级</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell13/manuell13.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell13/manuell13-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              在“骑手管理”--“骑手等级”--“等级制度”中设置相应骑手等级，每个等级支持自定义设置达标分数。<br/>
              不同等级支持不同计价方式、支持骑手等级周期结算。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell13/manuell13-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell13/manuell13-3.png'/>
        </div>
    )
  }
}

export default manuell13;