import React from 'react';
import ReactPlayer from 'react-player'

class manuell502 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>预约配送地址</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell502/manuell502.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell502/manuell502-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              <span style={{fontWeight: 'bold'}}>跑腿预设配送地址：</span><br/>
              即用户跑腿下单时，只可选择并使用平台设置的预设配送地址。<br/>
              在“预设配送地址设置”中设置预设地址，预设地址设置后，需再到跑腿场景里选择预设地址模式。
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>外卖预设配送地址：</span><br/>
              外卖预设配送地址：即用户外卖下单使用平台配送时，只可选择并使用平台设置的预设配送地址。在“预设配送地址设置”中设置。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell502/manuell502-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell502/manuell502-3.png'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '520px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell502/manuell502-4.png'/>
        </div>
    )
  }
}

export default manuell502;