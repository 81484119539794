import React from 'react';
import ReactPlayer from 'react-player'

class manuell41 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>丰富页面配置</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              <span style={{fontWeight: 'bold'}}>外卖首页展示</span><br/>
              在“外卖设置”--“外卖配置”中配置小程序外卖页面，可自定义设置模板内容即布局顺序。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41-2.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>外卖丰富模板</span><br/>
              在“外卖设置”--“外卖配置”中可选择“外卖首页模板”
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41-3.jpg'/>
            <p>
              <span style={{fontWeight: 'bold'}}>商家列表展示</span><br/>
              ①设置一些外卖营销活动：如“满减活动”、“优惠券”、“减配送费”等，营销活动能有效吸引用户进入店铺，可在“营销管理”--“外卖营销”设置相关活动。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41-4.jpg'/>
            <p>
              ②设置店铺虚拟销量和商品虚拟销量：合适的虚拟销量能让用户对店铺产生信任感。<br/>
              店铺虚拟销量设置：在“商户管理”--“商户列表”--“选择商家、点击编辑”--“外卖设置”--“店铺虚拟销量”设置虚拟销量。<br/>
              商品虚拟销量设置：商品虚拟销量需在商家端后台商品管理才可设置，在“商户管理”--“商户列表”--“选择商家、点击进入商户后台”。<br/>
              ③设置店铺评论宣传语：精彩的评论宣传语能有效侧面宣传店铺相关内容，吸引用户入店，“商户管理”--“商户列表”--“选择商家、点击编辑”--“外卖设置”--“评论宣传语”设置。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41-5.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '800px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell41/manuell41-6.jpg'/>

        </div>
    )
  }
}

export default manuell41;