import React from 'react';
import ReactPlayer from 'react-player'

class manuell601 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>跑腿优惠券</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持多种类型优惠券发放方式。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-2.jpg'/>
            <p>
              <span style={{fontWeight: 'bold'}}>新用户赠券：</span>未在平台下单用户，直接向用户账号赠送优惠券，刺激用户更快消费
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-3.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>首页赠券：</span>用户在发券期间第一次进入平台，则立即弹出优惠券。无需手动领取，直接进入用户卡包
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-4.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>指定用户赠券：</span>直接指定相应用户进行发放优惠券，指定用户无需领券直接进入用户卡包。发布后可在列表处选择用户发放
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-5.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>充值赠券：</span>用户可通过参与“余额充值赠送”活动获得此券。发布后，需在后台“充值赠送(用户)”中设置相关活动，选择此券做为活动赠送内容
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-6.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>积分兑换：</span>用户可通过参与“积分商城”活动兑换积分获得此优惠券。发布后，需在后台“积分商城”中设置相关活动，选择此优惠券做为活动赠送内容
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-7.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>分享领券：</span>分享裂变，用户参与"分享有礼"活动，达到指定要求即可获得优惠券
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-8.png'/>
            <p>
              <span style={{fontWeight: 'bold'}}>组合券：</span>可一次创建多张优惠券，组成组合券包，满足条件可直接领取，更加吸引用户下单
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell601/manuell601-9.png'/>
        </div>
    )
  }
}

export default manuell601;