/**
 * @FileDescription: 发展历程
 * @Author: Versient
 * @Date: 2024-09-10 17:06:40
 * @LastEditors: Versient
 * @LastEditTime: 2024-09-29 19:04:51
 */
import React from 'react'
import Title from "../../../../components/Title_2";
import { developingCauseImg } from './menuData';
// import Image from "next/image"
import '../page.css'

const englishStyle = { left: '50px', top: '38px', fontSize: '36px', color: '#FFFFFF', opacity: 0.1 }
const indicatorStyle = { left: '-2px' }
const titleStyle = { marginTop: '40px' }
const wordStyle = { color: '#FFFFFF' }

const developingCause = () => {
  return (
    <div className="mt-[60px] w-[100%] min-w-[1440px]  developing-cause"
      style={{ backgroundImage: `url(${developingCauseImg.bgImg})` }}>
      <div className="middle-container h-[656px] relative" style={{padding:"30px 0"}}>
        <div className='w-[150px] absolute left-0 top-0'>
          <Title
            baseInfo={{
              title: "发展历程",
              englishAlias: "HISTORY",
              titleStyle,
              englishStyle,
              indicatorStyle,
              wordStyle,
            }}
          />
        </div>
        <img
          src={developingCauseImg.img}
          alt="pic" layout="responsive" width="1340px" height="656px"
        />
      </div>
    </div>
  )
}

export default developingCause
