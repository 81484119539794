import React from 'react';
import ReactPlayer from 'react-player'

class manuell702 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>同城信息</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell702/manuell702.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell702/manuell702-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              信息发布是同城生活服务中比较重要的一个组成部分，主要是为本地用户提供一个交流信息的平台。由平台运营者设置信息发布模块的页面和信息分类，如表白墙、招聘、二手交易、同城交友等等，可以自定义设置，然后用户可以在对应的模块内发布信息、或者点赞评论、留言交流。<br />当然，运营者也可以设置付费发布的功能，发布需要支付费用，或者初次免费发布，后续发布再收费等等。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell702/manuell702-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell702/manuell702-3.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell702/manuell702-4.png'/>
        </div>
    )
  }
}

export default manuell702;