import React from 'react';
import ReactPlayer from 'react-player'

class manuell602 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>外卖优惠券</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell602/manuell602.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell602/manuell602-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持多种类型优惠券发放方式。
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>用户领取：</span>用户支持在小程序内手动领取优惠券
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>消费满额返券：</span>当用户消费结算金额达到要求时，完成订单后，自动发放商家优惠券。
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>指定用户赠券：</span>直接指定相应用户进行发放优惠券，指定用户无需领券直接进入用户卡包。发布后可在列表处选择用户发放
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>充值赠券：</span>用户可通过参与“余额充值赠送”活动获得此券。发布后，需在后台“充值赠送(用户)”中设置相关活动，选择此券做为活动赠送内容
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>积分兑换：</span>用户可通过参与“积分商城”活动兑换积分获得此优惠券。发布后，需在后台“积分商城”中设置相关活动，选择此优惠券做为活动赠送内容
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>分享领券：</span>分享裂变，用户参与"分享有礼"活动，达到指定要求即可获得优惠券
            </p>
            <p>
              <span style={{fontWeight: 'bold'}}>组合券：</span>可一次创建多张优惠券，组成组合券包，满足条件可直接领取，更加吸引用户下单
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell602/manuell602-2.png'/>
        </div>
    )
  }
}

export default manuell602;