/**
 * @FileDescription: 枚举数据
 */

/**
 * @remarks 轮播图
 */
export const swiperImg = [
  { 
    id: 1, 
    image: './images/takeout-banner.png',
    cuper: './images/swiper_about_sort.png', 
  }
]
export const developingCauseImg = {
  img:require("../../../../asset/about/developing-cause-content.png"),
  bgImg:require("../../../../asset/about/developing-cause-bgc.png")
}
/**
 * @remarks 关于壹立
 */
export const about = {
  bannerCard: [
    {
      id: 1, 
      title: '发展历程', 
      step: 'Part 01',
      icon: require("../../../../asset/about/about_part1.png"),
      detail: `<p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;">
        成都壹立科技有限公司，创立于天府蓉城“高端产业新城”——高新区，公司专注于互联网技术研发，是一家集软、硬件研发，科技化服务和数字化解决方案于一体的创新型科技企业，获得了国家“<span style="color: #1887E6">高新技术企业</span>”认证。
      </p>
      <p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;">
        公司秉持“以壹为始，立壹至上”的企业发展理念，在技术上精益求精，在产品上不断打磨，为客户提供先进、靠谱、安全的技术解决方案，赋能全行业数智化升级。
      </p>
      <p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;">
        坐拥庞大的客户群体，服务客户遍布于国内各省市及国外市场，累计<span style="color: #1887E6">合作全球超10000+合作商</span>，产品与服务赢得市场的广泛认可和一致好评。
      </p>
      `,
    },
    {
      id: 2, 
      title: '成果导向', 
      step: 'Part 02',
      icon: require('../../../../asset/about/about_part2.png'),
      detail: `<p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;">现目前，壹立科技旗下有包括壹脉销客电子名片、壹脉销客AI智能名片、趣来达跑腿系统、趣来达自配送系统、趣来达外卖系统等多个公司自主品牌、自主产权、自主研发设计的SaaS软件系统，以科技助力合作商数字化转型，推动着传统行业向“互联网+”高速发展，<span style="color: #1887E6">获得了来自国有企业的重点投资</span>。</p>
      <p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;"> 国资力量的加持，不仅为壹立科技注入了强劲的发展动力，也为公司带来了丰富的资源和更广阔的市场空间，助力公司在技术研发、市场拓展和品牌建设多方面实现质的飞跃，标志壹立科技的<span style="color: #1887E6">技术实力</span>、<span style="color: #1887E6">市场潜力</span>及<span style="color: #1887E6">社会价值</span>多方面将<span style="color: #1887E6">在行业处于更前沿的位置</span>。</p>`,
    },
    {
      id: 3, 
      title: '合作共腾飞', 
      step: 'Part 03',
      icon: require('../../../../asset/about/about_part3.png'),
      detail: `<p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;">未来，壹立科技将携手国企，将<span style="color: #1887E6">专业的技术、靠谱的产品</span>和<span style="color: #1887E6">强大的行业资源</span>与合作商共享，赋能项目良性发展，共同绘制向上优质发展的宏伟蓝图，一起为用户和社会创造更多价值。
      </p>
      <p style="font-size: 16px; text-indent: 2em; font-wdight: 400; color: #333; letter-spacing: .5px; line-height: 32px;">
        我们提供给您的不仅仅是一纸合同上的产品与服务，还有合作后，其他普通软件技术公司给不到的庞大的各端资源，能更好更全面地助力您的事业蓬勃发展。<span style="color: #1887E6">科技赋能</span>是我们的使命，<span style="color: #1887E6">合作共赢</span>是我们的愿景，期待我们的优质合作。
      </p>`
    }
  ]
}

/**
 * @remarks 团队文化
 */
export const culture = {
  bgImg:require("../../../../asset/about/corporat-culture.png"),
  rightIcon:require("../../../../asset/about/right.png"),
  bannerCard: [
    {
      id: 1, 
      title: '企业愿景', 
      step: 'Corporate Vision',
      text: '赋能客户成功，积极推动行业数字化升级'
    },
    {
      id: 2, 
      title: '企业使命', 
      step: 'Enterprise Mission',
      text: '致力于成为行业领先的数字解决方案服务商'
    },
    {
      id: 3, 
      title: '企业价值观', 
      step: 'Corporate Values',
      text: '正直诚信、专业创新、结果导向、客户第一'
    },
  ]
}




