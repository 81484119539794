import React from 'react';
import ReactPlayer from 'react-player'

class manuell609 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>营销表单</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell609/manuell609.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell609/manuell609-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持自定义设置“营销表单”，平台可设置多组件内容，用户可在小程序内参与活动，填写提交表单内容。在营销管理模块里的营销表单位置，点击“添加表单”可以新增“营销表单”的内容，系统内提供多个组件模块，自定义设置，设置完成并发布成功后，用户可在小程序内参与活动，填写提交表单信息，反馈给运营者，而运营者在后台就能看到完整的表单反馈情况。
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell609/manuell609-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell609/manuell609-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '450px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell609/manuell609-4.jpg'/>
        </div>
    )
  }
}

export default manuell609;