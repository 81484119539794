import React from 'react';
import ReactPlayer from 'react-player'

class manuell03 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>用户管理</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>所有进入过你的小程序，并登录授权过的用户信息都会存在于用户管理的列表里面，包括用户的ID和授权手机号。<br/>
              你可以通过这里，查看到你平台的所有用户信息，跟用户相关的数据统计分析，包括支付统计、下单排名、增长趋势曲线图等等，对用户”访问“、”留存“、”下单“等行为进行统计分析。还支持对平台内的用户发送统一通知公告，消息以“服务通知”的方式直接发到客户微信聊天列表。</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03-5.jpg'/>
            <img style={{ display: 'block' ,width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell03/manuell03-6.jpg'/>
        </div>
    )
  }
}

export default manuell03;