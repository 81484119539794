import React from 'react';
import ReactPlayer from 'react-player'

class manuell23 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>商家抽成</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              在“商户管理”--“商户列表”--“选择商家、点击编辑”--“费用设置”--“订单抽成”自定义设置抽成方案。<br/>
              抽成方式可根据订单类型进行独立抽成，也可设置统一抽成<br/>
              抽成方案支持“按比例抽成”和“按单固定抽成”<br/>
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23-3.png'/>
            <p>批量商家抽成设置</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23-5.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell23/manuell23-6.jpg'/>
        </div>
    )
  }
}

export default manuell23;