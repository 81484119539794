/**
 * @FileDescription: 企业文化
 * @Author: Versient
 * @Date: 2024-09-10 17:10:22
 * @LastEditors: Versient
 * @LastEditTime: 2024-09-29 19:10:41
 */
"use client"

import React, { useState } from 'react'
import Title from "../../../../components/Title_2";
import { culture } from './menuData'
// import Image from "next/image"

const englishStyle = { left: '66px', top: '38px', fontSize: '36px', color: '#FFFFFF', opacity: 0.1 }
const indicatorStyle = { left: '-2px' }
const wordStyle = { color: '#FFFFFF' }

const CorporateCulture = () => {
  const [currect, setCurrect] = useState(1)
  const [hoverState, setHoverStater] = useState(false)

  return (
    <div
      className="w-[100%] min-w-[1440px] h-[800px] relative -top-1 overflow-hidden"
      style={{
        backgroundImage: `url(${culture.bgImg})`,
        backgroundSize: "100% 800px",
      }}
    >
      <div className="middle-container h-[100%] flex">
        <div className="w-[400px] h-[100%] culture-banner relative">
          <div className="absolute left-[40px]">
            <Title
              baseInfo={{
                title: "团队文化",
                englishAlias: "CULTURE",
                englishStyle,
                indicatorStyle,
                wordStyle,
              }}
            />
          </div>
          <div className="mt-[180px] text-[#fff]">
            {culture.bannerCard.map((item) => (
              <div
                key={item.id}
                onMouseEnter={() => { setCurrect(item.id); setHoverStater(true) }}
                onMouseLeave={() => setHoverStater(false)}
                className={[
                  'mb-[15px]', 'h-[150px]', 'px-[40px]', 'flex', 'items-center', 'justify-between', 'box-border', 'cursor-pointer', 
                  'transition-all', 'duration-[1s]', item.id === currect ? 'bg-[#1887E633]' : 'bg-[#000000B3]'].join(' ')}>
                <div className="flex flex-col justify-center">
                  <h2 className="font-bold text-[#FFFFFF] text-[32px] font-[Source Han Sans CN]">{item.title}</h2>
                  <p className="m-0 font-light text-[#FFFFFF] text-[28px] font-[Source Han Sans CN]">{item.step}</p>
                </div>
                <img src={culture.rightIcon} alt="pic" width="28px" height="28px" />
              </div>
            ))}
          </div>
        </div>

        <div className={`'h-[100%] culture-content ${hoverState && 'animate__animated animate__lightSpeedInRight'}`}>
          <div className="mb-[40px] h-[60px] font-bold text-[78px] text-[#FFFFFF] leading-[32px] font-[Source Han Sans CN]">{culture.bannerCard[currect - 1].step}</div>
          <div className="h-[50px] font-bold text-[48px] text-[#FFFFFF] leading-[32px] font-[Source Han Sans CN] leading-[60px]">{culture.bannerCard[currect - 1].text}</div>
        </div>
      </div>
    </div>
  )
}

export default CorporateCulture
