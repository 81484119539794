import React from 'react'
import CarouselIndex from '../../components/Carousel'
import TitleRed from '../../components/TitleRed'
import Title from '../../components/Title'
import '../../css/DownloadCenter.scss'
import '../../css/MultiPlatform.scss'
import { Helmet } from 'react-helmet';


const Carousel_text = [
  {
    type:'douyin',
    carouselImage: "https://static.yiliit.com/qolai_com/miniprogram/pc_new_website/img_takeaway_banner_douyin2.png",
    text: [{ text: "聚焦5G发展，掘金", colorText: "抖音系短视频", opposite:'true' },{ text: "平台巨额流量池"}],
    textTwo:[{text:'充分整合多个短视频平台资源，实现',colorText:'流量变现'},{ text: "多终端实现用户高频使用，抢占",colorText:'5G时代红利'}],
    background: '#4B588B',
    fontWeight:400,
    marginBottom:"20px",
  }
]

const scopeArr = [
  {
    img: 'multi-platform-moduleC1',
    title: '多个入口',
    title2: '搜索购买、视频购买、评论购买、企业主页、客户留存等多方入口，提升品牌曝光率'
  },
  {
    img: 'multi-platform-moduleC2',
    title: '一键宣传',
    title2: '小程序一键视频分享，随时随地打造营销广告'
  },
  {
    img: 'multi-platform-moduleC3',
    title: '海量用户',
    title2: '抓住10亿级抖音用户，搭建专属私域流量池，让粉丝成为你的专属用户，让粉丝成为你的专属用户'
  },
  {
    img: 'multi-platform-moduleC4',
    title: '视频推广',
    title2: '视频内可一键挂载小程序，用户可边刷视频，边快速下单'
  }
]

let pageTimer = '' //停留时间

class DispatchSystem extends React.Component {
  state = {

  };

  componentWillMount() {
    // 获取进入页面的时间
		if (pageTimer === '') {
			pageTimer = parseInt(new Date().getTime() / 1000);
			console.log(pageTimer,'pageTimer')
		}
    this.props.onVisitClick({
      type: 1,
      domain: 'www.qolai.cn',
      page: 'multi_platform'
    },0)
  }

  componentWillUnmount() {
    // 离开时间减去进入时间
    const tempTimer = parseInt(new Date().getTime() / 1000) - pageTimer;
    console.log(tempTimer,'tempTimer')
    // 清空时间戳
    pageTimer = '';
    if (tempTimer > 0) {
      this.props.onVisitClick({
        type: 2,
        domain: 'www.qolai.cn',
        page: 'multi_platform',
        time: tempTimer
      },0)
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>抖音外卖-团购-趣来达</title>
          <meta name="Description" content="趣来达O2O本地平台系统，一次性接入字节旗下各个APP，以小程序为内容载体，利用字节跳动推荐算法与搜索能力把小程序准确推荐给用户，为本地生活业务提供高效的场景搭建和丰富的玩法，借力短视频与直播流量风口，为平台快速营销、宣传、裂变，累积更多用户。" />
          <meta name="Keywords" content="抖音外卖,抖音团购" />
        </Helmet>
        {/**顶部banner**/}
        <CarouselIndex data={Carousel_text} />

        <div className="d-flex justify-content-center" style={{background:'#F7F9FC'}}>
          <div className="download-center">
            <div className="download-center d-flex " style={{height:'600px',width:'1440px'}}>
              <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleA.png`)} style={{width:'720px',height:'500px',marginRight:"153px"}}/>
              <div className="d-flex flex_column" style={{width:'658px'}}>
                <div style={{fontSize:'30px',fontWeight:'bold',marginBottom:'29px',color:'#121213'}}>
                  产品概述
                </div>
                <div style={{fontSize:'24px',fontWeight:'bold',color:'#121213',marginBottom:"15px"}}>
                  在中国，所有的生意都值得再做一次，
                </div>
                <div style={{fontSize:'24px',fontWeight:'bold',color:'#121213',marginBottom:"30px"}}>
                 尤其是在消费升级的时代。
                </div>
                <div style={{fontSize:'16px',lineHeight:'25px',color:'#707070'}}>
                  趣来达O2O本地平台系统，一次性接入字节旗下各个APP，以小程序为内容载体，利用字节跳动推荐算法与搜索能力把小程序准确推荐给用户，为本地生活业务提供高效的场景搭建和丰富的玩法，借力短视频与直播流量风口，为平台快速营销、宣传、裂变，累积更多用户。
                </div>
                
              </div>
            </div>
          </div>
        </div>

        {/* 为什么选择趣来达外卖系统 */}
        <div style={{ marginTop: '80px' }}>
          <TitleRed titleFront="入局本地生活"
            spanColor="抖音市场"
            titleRear="，这块蛋糕有多大？"
            text="Tik Tok bazaar"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center">
            <div className="d-flex flex_column align-cen jscen" style={{ width: '1440px' }}>
              <p style={{ fontSize: '24px', fontWeight: 'bold', color: ' #121213', marginBottom: '60px' }}>搭乘时代顺风车，紧握短视频流量风口，瓜分8亿日活用户</p>
              <div>
                <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleB.png`)} style={{ width: '1440px', height: "720px", marginBottom: '100px' }} />
              </div>

            </div>
          </div>
        </div>

        {/**趣来达跑腿配送系统 - 价值亮点**/}
        <div style={{background:'#F7F9FC',paddingTop:'98px'}}>
          <Title title="产品赋能"
            text="Products can assign"
            width={710}
            height={66} />
          <div className="d-flex justify-content-center">
            <div className="d-flex flex_column" style={{ width: "1440px" }}>
              <div className="service-scope-top" style={{ marginBottom: '300px' }}>
                {scopeArr.map((item, index) => {
                  return (
                    <div className='box' style={{ backgroundImage: `url(${require(`../../asset/multiPlatform/${item.img}.png`)})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat', width: '337px', height: '337px' }}>
                      <div className="scope-content">
                        <div className="scope-content-title">
                          {item.title}
                        </div>
                        <div className="scope-content-bottom">{item.title2}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>

        {/**实力互联网技术型企业，全套服务流程**/}
        <div style={{ padding: "80px 0", marginTop: "-193px", background: "#F8F8F8", backgroundImage: `url(${require('../../asset/systemoto/system-oto-modulesB-bg.png')})`, backgroundSize: '100% 100%', backgroundRepeat: ' no-repeat' }}>
          <Title
            title="一个产品，多个平台"
            text="multi-platform"
            titleColor='#FFFFFF'
            textColor='rgb(255, 255, 255)'
            decColor='#FFFFFF'
            width={750}
            height={68}
          />
          <div className="d-flex justify-content-center">
            <div className="d-flex" style={{ width: "1440px" }}>
              <div style={{ width: "432px", marginRight: '71px', paddingTop: '80px' }}>
                <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#fff' }}>
                  一个字节小程序
                </div>
                <div style={{ fontSize: '24px', color: '#fff' }}>
                  即可实现
                </div>
                <div style={{ fontSize: '24px', color: '#fff' }}>
                  旗下多平台同时公共全面提升品牌曝光率
                </div>
              </div>

              <div style={{ width: "432px", height: '382px', marginBottom: '100px', position: "relative" }}>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "140px", left: '0', background: 'rgba(253,58,60,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD1.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  头条极速版
                </div>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "0px", left: '224px', background: 'rgba(43,207,136,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD2.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  抖音极速版
                </div>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "252px", left: '224px', background: 'rgba(24,135,230,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD3.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  火山小视频
                </div>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "124px", left: '429px', background: 'rgba(253,99,78,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD4.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  今日头条
                </div>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "302px", left: '539px', background: 'rgba(255,121,30,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD5.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  抖音火山版
                </div>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "0", left: '620px', background: 'rgba(74,126,230,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD6.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  抖音
                </div>
                <div className="multi-platform-moduleD-circle" style={{ position: 'absolute', top: "178px", left: '753px', background: 'rgba(255,98,143,0.3)' }}>
                  <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleD7.png`)} style={{ width: '80px', height: "80px", marginBottom: '10px' }} />
                  皮皮虾
                </div>


              </div>

            </div>
          </div>
        </div>
        {/* 为什么选择趣来达外卖系统 */}
        <div style={{ marginTop: '80px' }}>
        <Title
            title="趣来达，一套支持多平台运营的系统"
            text="multi-platform system"
            width={750}
            height={68}
          />
          <div className="d-flex justify-content-center">
            <div className="d-flex jscen">
              <div>
                <img alt="" src={require(`../../asset/multiPlatform/multi-platform-moduleE.png`)} style={{ width: '100%',maxWidth:'1920px', height: "253px", marginBottom: '100px' }} />
              </div>

            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default DispatchSystem;