import React from 'react';
import ReactPlayer from 'react-player'

class manuell501 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>中转配送</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell501/manuell501.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell501/manuell501-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              系统支持中转配送，即分段配送，采用多骑手配送方式；适用于校园、园区等特殊场景。<br/>
              此类型订单将由两位进行配送，然后骑手A从"取货地址"至"中转站地址"，骑手B接单或扫码录单后后，再由骑手B从"中转站地址"配 送至"送达地址"<br/><br/>
              <span style={{fontWeight: 'bold'}}>跑腿中转配送</span>：支持自定义设置中转站即配送相关设置。在“跑腿设置“--“跑腿中转配送”中进行设置
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell501/manuell501-2.jpg'/>
            <p>
              <span style={{fontWeight: 'bold'}}>外卖中转配送</span>：支持自定义设置中转站即配送相关设置。在“外卖设置“--“外卖中转配送”中进行设置
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell501/manuell501-3.jpg'/>
            <p>
              <span style={{fontWeight: 'bold'}}>商户中转配送</span>：支持自定义设置中转站即配送相关设置。在“商户设置“--“商户发单中转配送”中进行设置
            </p>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell501/manuell501-4.jpg'/>
        </div>
    )
  }
}

export default manuell501;