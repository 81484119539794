import axios from 'axios';

// 获取资讯详情
export async function newsDetail(newsType, newsId) {
  const formData = new FormData();
  formData.append('news_type', newsType);
  formData.append('news_id', newsId);
  const result = axios.post('/api/Company/getNewDetail', formData).catch(result => result)
  return result
}

// 获取上一片和下一片的新闻资讯
export async function getOtherNews(newsType, newsId) {
  const formData = new FormData();
  formData.append('news_type', newsType);
  formData.append('news_id', newsId);
  const result = axios.post('/api/Company/getOtherNews', formData).catch(result => result)
  return result
}