import React from 'react';
import ReactPlayer from 'react-player'

class manuell31 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>跑腿页面设置</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell31/manuell31.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell31/manuell31-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              使用趣来达跑腿配送系统，每个运营者都可以打造独立风格和个性化的跑腿平台。<br/>
              在“跑腿设置”--“跑腿页面设置”中配置小程序跑腿页面，可自定义设置“顶部轮播图”、“骑手虚拟人数”、“底部广告图”和“快捷下单设置”。<br/><br/>
              设置不同风格类型：在“跑腿设置”--“跑腿页面设置”--“模板选择”可设置不同类型的跑腿页面
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell31/manuell31-2.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell31/manuell31-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell31/manuell31-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell31/manuell31-5.jpg'/>
        </div>
    )
  }
}

export default manuell31;