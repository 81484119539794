import React from 'react';
import ReactPlayer from 'react-player'

class manuell11 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>骑手入驻</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell11/manuell11.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell11/manuell11-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              ①系统添加：支持后台输入手机号添加骑手，也可通过“骑手邀请链接”、“骑手邀请有礼”和小程序“骑手招聘”进行骑手入驻
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell11/manuell11-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell11/manuell11-3.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell11/manuell11-4.jpg'/>
            <p> ②骑手也可在“骑手端”APP进行注册登录入驻平台。 </p>
            <img style={{ display: 'block' ,marginBottom: '0px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell11/manuell11-5.png'/>
        </div>
    )
  }
}

export default manuell11;