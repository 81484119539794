import React from 'react';
import ReactPlayer from 'react-player'

class manuell22 extends React.Component {

  render() {
    return (
        <div>
            <h1 style={{fontWeight: 'bold'}}>商家发单配送费</h1>
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>视频介绍</h3>
            <ReactPlayer width={840} height={450} controls url='https://static.yiliit.com/qolai_com/miniprogram/video/manuell22/manuell22.mp4' light='https://static.yiliit.com/qolai_com/miniprogram/video/manuell22/manuell22-1.jpg' playing />
            <h3 style={{fontWeight: 'bold',padding: '15px 0'}}>内容介绍</h3>
            <p>
              商家配送费分为“商户手动下单配送费”和“商户外卖订单配送费”，都可在“配送费计价”处批量设置区别：<br/>
              ①商户手动下单配送费：为商家在PC端或APP端手动下单时所需支付的配送费。<br/>
              ②商户外卖订单配送费：为用户在小程序进行外卖下单，产生的外卖订单，商家需支付此外卖订单的配送费。<br/>
              注：此配送费非用户支付的配送费
            </p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell22/manuell22-2.png'/>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell22/manuell22-3.png'/>
            <p>计费方式包含“按单计价”、“比例计价”、“里程计价”、“区间费用”、“时段计价”、“重量计价”、“预设费用”、“范围计价”、“天气附加”、“外卖预设地址计价”、“单额计价”、“最低起送价”</p>
            <img style={{ display: 'block' ,marginBottom: '40px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell22/manuell22-4.jpg'/>
            <img style={{ display: 'block' ,marginBottom: '0px',width: '840px'}} alt='' src='https://static.yiliit.com/qolai_com/miniprogram/video/manuell22/manuell22-5.jpg'/>
        </div>
    )
  }
}

export default manuell22;